import {Outlet} from 'react-router-dom';
import {styled} from '@mui/material';
import Logo from '../components/Logo';
import {useScript} from "../hooks/useScript";

const HeaderStyle = styled('header')(({theme}) => ({
  marginBottom: 10,
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  }
}));

export default function LogoOnlyLayout() {
  useScript({
    url: "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit",
    position: "head",
    async: false,
  });
  useScript({
    url: "//translate.google.com/#en/hi/Hello",
    position: "head",
    async: false,
  });
  useScript({
    url: "/GoogleTranslateInit.js",
    position: "head",
  });
  return (
      <>
        <HeaderStyle>
          <Logo/>
        </HeaderStyle>
        <Outlet/>
      </>
  );
}
