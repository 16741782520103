import {Controller} from "react-hook-form";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import {isValidPhoneNumber} from 'libphonenumber-js';
import {
  DesktopDatePicker,
  DesktopDateTimePicker,
  LocalizationProvider,
  MobileDateTimePicker
} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {format, isValid} from "date-fns";

export const ControlledAutocomplete = (props: any) => {
  const {
    name,
    options,
    control,
    multiple,
    required,
    disabled,
    freeSolo,
    autoComplete,
    autoCompleteProps,
    ...otherProps
  } = props;
  return (
      <Controller
          control={control}
          defaultValue={multiple ? [] : null}
          name={name}
          render={({field: {onChange, value, ...props}}) => (
              <Autocomplete
                  {...props}
                  {...autoCompleteProps}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  onBlur={(newValue) => {
                    let newInput = newValue?.target as HTMLInputElement;
                    if (multiple) {
                      if (freeSolo && newInput?.value) {
                        onChange([...value, newInput?.value]);
                      }
                    } else {
                      if (freeSolo && newInput?.value) {
                        onChange(newInput?.value);
                      }
                    }
                  }}
                  options={options}
                  freeSolo={freeSolo}
                  disabled={disabled}
                  multiple={multiple}
                  value={value ?? (multiple ? [] : null)}
                  renderInput={(params) =>
                      <TextField
                          {...params}
                          {...otherProps}
                          required={required ?
                              (multiple ? !(value?.length > 0) : true)
                              : false}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: autoComplete,
                          }}
                      />
                  }
              />
          )}
      />
  );
};

export const ControlledMuiPhoneNumber = (props: any) => {
  const {name, control, required, ...otherProps} = props;
  return (
      <Controller
          control={control}
          name={name}
          rules={{
            validate: (value) => {
              if (required) {
                if (value && isValidPhoneNumber(value)) {
                  return true;
                }
                return "Invalid Phone Number";
              } else {
                if (value && !isValidPhoneNumber(value)) {
                  return "Invalid Phone Number";
                }
                return true;
              }
            },
          }}
          render={({field, fieldState}) => (
              <MuiPhoneNumber
                  {...field}
                  {...otherProps}
                  required={required}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
              />
          )}
      />
  );
};

export const ControlledSelect = (props: any) => {
  const {name, label, control, required, items, ...otherProps} = props;
  return (
      <Controller
          control={control}
          defaultValue={''}
          name={name}
          render={({field}) => (
              <FormControl
                  required={required}
                  fullWidth
              >
                <InputLabel>{label}</InputLabel>
                <Select
                    label={label}
                    {...field}
                    {...otherProps}
                >
                  {items && items.map((item: any) => (
                      <MenuItem
                          value={item}
                          key={item}
                      >
                        {item}
                      </MenuItem>
                  ))}
                </Select>
              </FormControl>
          )}
      />
  );
};

export const ControlledDesktopDatePicker = (props: any) => {
  const {
    name,
    control,
    inputFormat,
    disableFuture,
    minimumDate,
    disabled,
    desktopDatePickerProps,
    ...otherProps
  } = props;
  return (<LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
            control={control}
            defaultValue={null}
            name={name}
            rules={{
              validate: (value) => {
                if (value && !isValid(new Date(value))) {
                  return "Invalid Date";
                }
                if (value && disableFuture &&
                    new Date(value).getTime() > Date.now()) {
                  return "Can't be a future date.";
                }
                if (value && minimumDate &&
                    new Date(value).getTime() < minimumDate) {
                  return "Invalid Date";
                }
                return true;
              },
            }}
            render={({
                       field,
                       fieldState,
                     }) => (
                <DesktopDatePicker
                    {...desktopDatePickerProps}
                    disableOpenPicker
                    inputFormat={inputFormat}
                    value={field.value}
                    disableFuture={disableFuture}
                    disabled={disabled}
                    onChange={(value: any) => {
                      let newValue = value;
                      try {
                        newValue = format(value, inputFormat);
                      } catch (error) {
                        //todo how to avoid this try/catch?
                      }
                      field.onChange(newValue);
                    }}
                    renderInput={(params) =>
                        <TextField
                            fullWidth
                            {...params}
                            {...otherProps}
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error?.message}
                            InputProps={{
                              ...params.InputProps,
                              type: "date",
                            }}
                            {...field}
                        />
                    }
                />
            )}
        />
      </LocalizationProvider>
  );
};

export const ControlledDesktopDateTimePicker = (props: any) => {
  const {
    name,
    control,
    inputFormat,
    disableFuture,
    minimumDate,
    disabled,
    desktopDateTimePicker,
    ...otherProps
  } = props;
  return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
            control={control}
            defaultValue={null}
            name={name}
            rules={{
              validate: (value) => {
                if (value && !isValid(new Date(value))) {
                  return "Invalid Date";
                }
                if (value && disableFuture &&
                    new Date(value).getTime() > Date.now()) {
                  return "Can't be a future date.";
                }
                if (value && minimumDate &&
                    new Date(value).getTime() < minimumDate) {
                  return "Invalid Date";
                }
                return true;
              },
            }}
            render={({
                       field,
                       fieldState,
                     }) => (
                <DesktopDateTimePicker
                    {...desktopDateTimePicker}
                    disableOpenPicker
                    inputFormat={inputFormat}
                    value={field.value}
                    disableFuture={disableFuture}
                    disabled={disabled}
                    onChange={(value: any) => {
                      let newValue = value;
                      try {
                        newValue = format(value, inputFormat);
                      } catch (error) {
                        //todo how to avoid this try/catch?
                      }
                      field.onChange(newValue);
                    }}
                    renderInput={(params) =>
                        <TextField
                            fullWidth
                            {...params}
                            {...otherProps}
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error?.message}
                            InputProps={{
                              ...params.InputProps,
                              type: "date",
                            }}
                            {...field}
                        />
                    }
                />
            )}
        />
      </LocalizationProvider>
  );
};

export const ControlledMobileDateTimePicker = (props: any) => {
  const {
    name,
    control,
    inputFormat,
    disableFuture,
    minimumDate,
    disabled,
    mobileDateTimePicker,
    ...otherProps
  } = props;
  return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
            control={control}
            defaultValue={null}
            name={name}
            rules={{
              validate: (value) => {
                if (value && !isValid(new Date(value))) {
                  return "Invalid Date";
                }
                if (value && disableFuture &&
                    new Date(value).getTime() > Date.now()) {
                  return "Can't be a future date.";
                }
                if (value && minimumDate &&
                    new Date(value).getTime() < minimumDate) {
                  return "Invalid Date";
                }
                return true;
              },
            }}
            render={({
                       field,
                       fieldState,
                     }) => (
                <MobileDateTimePicker
                    {...mobileDateTimePicker}
                    value={field.value}
                    disableFuture={disableFuture}
                    disabled={disabled}
                    onChange={(value: any) => {
                      let newValue = value;
                      try {
                        newValue = format(value, inputFormat);
                      } catch (error) {
                        //todo how to avoid this try/catch?
                      }
                      field.onChange(newValue);
                    }}
                    renderInput={(params) =>
                        <TextField
                            fullWidth
                            {...params}
                            {...otherProps}
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error?.message}
                            InputProps={{
                              ...params.InputProps,
                            }}
                            {...field}
                        />
                    }
                />
            )}
        />
      </LocalizationProvider>
  );
};

export const ControlledCheckbox = (props: any) => {
  const {name, control, label, ...otherProps} = props;
  return (
      <Controller
          control={control}
          name={name}
          render={({field}) => (
              <FormControlLabel
                  control={
                    <Checkbox
                        {...field}
                        {...otherProps}
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                    />
                  }
                  label={label}
              />
          )}
      />
  );
};
