import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Checkbox,
  Fab,
  Grid,
  Typography
} from "@mui/material";
import {questionnaire} from "../const/questionnaire";
import {useEffect, useState} from "react";
import {usePatient} from "../contexts/PatientProvider";
import {PatientContextInterface} from "../interfaces/PatientContextInterface";
import {useNavigate} from "react-router-dom";

export default function QuestionnaireSymptoms(props: any) {
  const {setStep} = props;

  const {
    testingFacility,
    setPatientId,
    updateQuestionnaire
  }: PatientContextInterface = usePatient();
  const [symptomChoice, setSymptomChoice] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  let navigate = useNavigate();

  const selectSymptom = (name: string) => {
    if (symptomChoice === name) {
      setSymptomChoice("");
    } else {
      setSymptomChoice(name);
    }
  }

  useEffect(() => {
    if (symptomChoice) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [symptomChoice]);

  return (
      <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
          sx={{
            mt: 2,
            px: 2,
          }}
      >
        <Grid item xs={12}>
          <Typography
              variant={"h4"}
              sx={{textAlign: "center"}}>
            Are you experiencing any symptoms of COVID-19?
          </Typography>
        </Grid>
        {questionnaire.symptoms.map((symptom) => {
          return (
              <Grid
                  key={symptom.name}
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  sm={6}
                  xs={12}>
                <Card
                    sx={{maxWidth: "466px"}}>
                  <CardActionArea
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "start",
                      }}
                      onClick={() => selectSymptom(symptom.name)}
                  >
                    <CardHeader
                        avatar={
                          <Checkbox
                              style={{pointerEvents: "none"}}
                              checked={symptomChoice === symptom.name}
                          />
                        }
                        title={
                          <Typography variant={"h5"}>
                            {symptom.name}
                          </Typography>
                        }
                        sx={{pb: 0}}/>
                    <CardContent>
                      <Typography align={"left"}>
                        {symptom.details}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
          );
        })}
        <Grid
            item
            xs={12}
            sx={{
              textAlign: "center",
              position: "fixed",
              bottom: "1%",
            }}>
          <Fab
              variant={"extended"}
              onClick={() => {
                if (setPatientId) {
                  setPatientId("");
                }
                navigate("/?site=" + testingFacility);
              }}
          >
            Sign Out
          </Fab>
          <Fab
              color={"primary"}
              variant={"extended"}
              disabled={!disableButton}
              onClick={() => {
                if (symptomChoice === "No") {
                  if (updateQuestionnaire) {
                    updateQuestionnaire({symptom: "None"});
                  }
                  setStep("exposure");
                } else {
                  setStep("symptomDetails");
                }
              }}
          >
            Confirm
          </Fab>
        </Grid>
      </Grid>
  );
}
