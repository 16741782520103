import {useEffect} from 'react';
import {PatientContextInterface} from "../interfaces/PatientContextInterface";
import {usePatient} from "../contexts/PatientProvider";

export const useParamCheck = (props: { checkSite?: boolean, checkPid?: boolean }) => {
  const {checkSite, checkPid} = props;
  const {
    setTestingFacility,
    setPatientId,
    setShowBackdrop,
  }: PatientContextInterface = usePatient();

  useEffect(() => {
    const site = new URLSearchParams(window.location.search).get("site");
    const pid = new URLSearchParams(window.location.search).get("pid");
    if ((!checkSite || site) && (!checkPid || pid)) {
      if (site && setTestingFacility) {
        setTestingFacility(site);
      }
      if (pid && setPatientId) {
        setPatientId(pid);
      }
    } else {
      if (setShowBackdrop) {
        setShowBackdrop(true);
      }
    }
  }, [checkPid, checkSite, setPatientId, setShowBackdrop, setTestingFacility]);
}
