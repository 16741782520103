import Page from "../components/Page";
import {Alert, AlertTitle, Backdrop, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import QuestionnaireSymptoms from "../components/QuestionnaireSymptoms";
import QuestionnaireSymptomDetails from "../components/QuestionnaireSymptomDetails";
import QuestionnaireExposure from "../components/QuestionnaireExposure";
import QuestionnaireExposureDetails from "../components/QuestionnaireExposureDetails";
import QuestionnaireSevere from "../components/QuestionnaireSevere";
import {PatientContextInterface} from "../interfaces/PatientContextInterface";
import {usePatient} from "../contexts/PatientProvider";
import {useParamCheck} from "../hooks/useParamCheck";

export default function Questionnaire() {
  const {
    showBackdrop,
  }: PatientContextInterface = usePatient();

  const [step, setStep] = useState("symptoms");
  const [severe, setSevere] = useState(false);
  const [showSevere, setShowSever] = useState(false);

  useEffect(() => {
    if (step === "severe") {
      setSevere(true);
    }
    if (severe && step !== "severe") {
      setShowSever(true);
    } else {
      setShowSever(false);
    }
  }, [severe, step]);

  useParamCheck({checkSite: true, checkPid: true});

  return (
      <Page title={"LabPort | Questionnaire"}>
        <Backdrop
            style={{zIndex: 4, backgroundColor: "rgba(0,0,0,0.8)"}}
            color={"black"}
            open={showBackdrop ?? false}>
          <Typography style={{color: "#fff", textAlign: "center", margin: "20px"}}>
            The system could not recognize where you came from.
            Please re-scan the QR code and start over.
          </Typography>
        </Backdrop>
        {showSevere &&
          <Alert severity="error">
            <AlertTitle>
              If you feel like you are having a medical emergency, please call 9-1-1.
            </AlertTitle>
            <strong>Call ahead before visiting your healthcare provider. </strong>
            This will help the healthcare provider's office keep other people from getting infected
            or exposed.
          </Alert>
        }
        {step === "symptoms" &&
            <QuestionnaireSymptoms setStep={setStep}/>
        }
        {step === "symptomDetails" &&
            <QuestionnaireSymptomDetails setStep={setStep}/>
        }
        {step === "exposure" &&
            <QuestionnaireExposure setStep={setStep}/>
        }
        {step === "exposureDetails" &&
            <QuestionnaireExposureDetails setStep={setStep}/>
        }
        {step === "severe" &&
            <QuestionnaireSevere setStep={setStep}/>
        }
      </Page>
  );
}
