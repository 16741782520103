import {Box, BoxProps} from "@mui/material";

export default function Item(props: BoxProps) {
  const {sx, ...other} = props;
  return (
      <Box
          sx={{
            m: 1,
            ...sx,
          }}
          {...other}
      />
  );
}
