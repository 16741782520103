import {BrowserRouter, Route, Routes} from 'react-router-dom';
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import SignIn from "./pages/SignIn";
import Questionnaire from "./pages/Questionnaire";
import UploadResults from "./pages/UploadResults";
import ThankYou from "./pages/ThankYou";
import Page404 from "./pages/Page404";

export default function Router() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LogoOnlyLayout/>}>
            <Route path="/" element={<SignIn/>}/>
            <Route path="questionnaire" element={<Questionnaire/>}/>
            <Route path="upload" element={<UploadResults/>}/>
            <Route path="thankyou" element={<ThankYou/>}/>
            <Route path="*" element={<Page404/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
  );
}
