export const questionnaire = {
  symptoms: [
    {
      name: "Yes",
      details: "I am having any symptoms",
    },
    {
      name: "No",
      details: "I am not having any symptoms",
    },
  ],
  symptomDetails: [
    {
      name: "Mild",
      list: [
        "I have a fever between 100.4° F and 102° F, am feeling feverish, or feel warm to the touch.​",
        "I have a new or worsening cough.",
        "I have a new or worsening sore throat.",
        "I am having flu-like symptoms (chills, runny or stuffy nose, headache, body aches, and/or feeling tired).",
        "I am having shortness of breath that is not limiting my ability to speak.",
        "I have new loss of taste or smell.",
        "I have new nausea or vomiting.",
        "I have new diarrhea",
      ],
    },
    {
      name: "Severe",
      list: [
        "I have a fever of 102° F or higher, OR I have a fever that has lasted longer than 48 hours.",
        "I can’t speak in full sentences or do simple activities without feeling short of breath.",
        "I am having severe coughing spells, or I am coughing up blood. My lips or face are blue.",
        "I have severe and constant pain or pressure in my chest.",
        "I feel very tired or lethargic.",
        "I feel dizzy, lightheaded, or too weak to stand.",
        "I am having slurred speech or seizures.",
        "I do not feel like I can stay at home because I feel seriously ill.",
      ],
    },
  ],
  exposure: [
    {
      name: "Yes",
      details: "I have been exposed to COVID-19 in the last 2 days.",
    },
    {
      name: "No",
      details: "I have not been exposed to COVID-19 in over 48 hours.",
    },
  ],
  exposureDetails: [
    {
      name: "Today",
      details: "I have been exposed to COVID-19 today.",
    },
    {
      name: "Yesterday",
      details: "I have been exposed to COVID-19 yesterday.",
    },
  ],
  proximity: [
    {
      name: "Exposed or Sick Contact",
      details: "Yes, I have been in close proximity* to someone who has been diagnosed with or presumed to have COVID-19.",
      asterisk: "*within 6 ft. of the person for a prolonged period of time or being coughed on",
    },
    {
      name: "Testing Recommended",
      details: "Yes, I have been in close proximity* to someone who has been diagnosed with or presumed to have COVID-19.",
      asterisk: "*within 6 ft. of the person for a prolonged period of time or being coughed on",
    },
    {
      name: "Congregate Setting",
      details: "Yes, I live or work in a place where people reside, meet, or gather in close proximity.*",
      asterisk: "*Includes nursing homes or other long term care facilities, healthcare settings, office buildings, workplaces, schools, group homes, homeless shelters, prisons, and detention centers.",
    },
    {
      name: "Unexposed",
      details: "No, I have not been exposed.",
    },
  ],
};
