import {useEffect} from "react";
import lottie from 'lottie-web';
import {Grid, Typography} from "@mui/material";
import thankYouAnimation from "../components/animate/thankYouAnimation.json"
import Page from "../components/Page";

export default function ThankYou() {

  useEffect(() => {
    lottie.loadAnimation({
      // @ts-ignore
      container: document.getElementById("thank-you-animation"),
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: thankYouAnimation,
    })
  }, []);

  return (
      <Page title={"LabPort | Thank You"}>
        <Grid container direction={"row"} alignItems={"center"}
              justifyContent={"center"}>
          <Grid xs={12} sm={4} md={2} lg={2} item id={"thank-you-animation"}/>
          <Grid item xs={12} lg={12}>
            <Typography
                color={"#F66D6D"}
                fontWeight={2}
                sx={{textAlign: "center", fontSize: "5rem"}}>
              Thank you!
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12} style={{textAlign: "center"}}>
            <Typography>
              You have successfully self-reported your test result.
              <br/>
              <br/>
              You should shortly receive a confirmation text message.
              <br/>
              Once your report has been reviewed, you will receive a notification.
            </Typography>
            <Typography color={"#F66D6D"}>
              <br/>Questions? Please contact us at <a href={"mailto:support@labport.app"}>
              support@labport.app</a>.
            </Typography>
          </Grid>
        </Grid>
      </Page>
  );
}
