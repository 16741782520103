import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Checkbox,
  Fab,
  Grid,
  Typography
} from "@mui/material";
import {questionnaire} from "../const/questionnaire";
import {useEffect, useState} from "react";
import {PatientContextInterface} from "../interfaces/PatientContextInterface";
import {usePatient} from "../contexts/PatientProvider";
import {useNavigate} from "react-router-dom";

export default function QuestionnaireExposureDetails(props: any) {
  const {setStep} = props;

  const {testingFacility, patientId, updateQuestionnaire}: PatientContextInterface = usePatient();
  const [exposureChoice, setExposureChoice] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  let navigate = useNavigate();

  const selectExposure = (name: string) => {
    if (exposureChoice === name) {
      setExposureChoice("");
    } else {
      setExposureChoice(name);
    }
  }

  useEffect(() => {
    if (exposureChoice) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [exposureChoice]);

  return (
      <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
          sx={{
            mt: 2,
            px: 2,
          }}
      >
        <Grid item xs={12}>
          <Typography
              variant={"h4"}
              sx={{textAlign: "center"}}>
            Have you recently been exposed to COVID-19?
          </Typography>
        </Grid>
        {questionnaire.exposureDetails.map((exposure) => {
          return (
              <Grid
                  key={exposure.name}
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  sm={6}
                  xs={12}>
                <Card
                    sx={{maxWidth: "466px"}}>
                  <CardActionArea
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "start",
                      }}
                      onClick={() => selectExposure(exposure.name)}
                  >
                    <CardHeader
                        avatar={
                          <Checkbox
                              style={{pointerEvents: "none"}}
                              checked={exposureChoice === exposure.name}
                          />
                        }
                        title={
                          <Typography variant={"h5"}>
                            {exposure.name}
                          </Typography>
                        }
                        sx={{pb: 0}}/>
                    <CardContent>
                      <Typography align={"left"}>
                        {exposure.details}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
          );
        })}
        <Grid
            item
            xs={12}
            sx={{
              textAlign: "center",
              position: "fixed",
              bottom: "1%",
            }}
        >
          <Fab
              variant={"extended"}
              onClick={() => {
                setStep("exposure");
              }}
          >
            Back
          </Fab>
          <Fab
              color={"primary"}
              variant={"extended"}
              disabled={!disableButton}
              onClick={() => {
                if (updateQuestionnaire) {
                  updateQuestionnaire({exposure: exposureChoice});
                }
                navigate("/upload?site=" + testingFacility + "&pid=" + patientId);
              }}
          >
            Confirm
          </Fab>
        </Grid>
      </Grid>
  );
}
