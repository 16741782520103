import {useEffect, useState} from 'react';

export const useClientIP = () => {
  const [clientIP, setClientIP] = useState();

  useEffect(() => {
    fetch('https://www.cloudflare.com/cdn-cgi/trace').then(data => {
      data.text().then(response => {
        setClientIP(Object.fromEntries(
            response.trim().split("\n").map(e => e.split("="))).ip);
      }).catch(e => {
        console.log(e);
      });
    }).catch(e => {
      console.log(e);
    });
  }, []);

  return clientIP;
}
