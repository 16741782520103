import {Helmet} from 'react-helmet-async';
import {forwardRef, ReactNode} from 'react';
import {Box} from '@mui/material';

interface PageInterface {
  children?: ReactNode;
  title: string;
  other?: {};
}

const Page = forwardRef(({
  children,
  title = '',
  ...other
}: PageInterface, ref) => (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
));

export default Page;
