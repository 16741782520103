import {Backdrop, Box, Button, LinearProgress, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import axios from "axios";
import {parsePhoneNumber} from "libphonenumber-js";
import {useNavigate} from "react-router-dom";
import {
  ControlledDesktopDatePicker,
  ControlledMuiPhoneNumber
} from "../components/ControlledComponents";
import {usePatient} from "../contexts/PatientProvider";
import {envConfig} from "../const/envConfig";
import Page from "../components/Page";
import Item from "../components/Item"
import {PatientContextInterface} from "../interfaces/PatientContextInterface";
import {useParamCheck} from "../hooks/useParamCheck";

export default function SignIn() {
  const {
    patientId,
    setPatientId,
    testingFacility,
    executeRecaptcha,
    showBackdrop,
  }: PatientContextInterface = usePatient();
  const {
    control,
    handleSubmit,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  let navigate = useNavigate();

  useParamCheck({checkSite: true});

  useEffect(() => {
    if (patientId && testingFacility) {
      navigate("/questionnaire?site=" + testingFacility + "&pid=" + patientId);
      setLoading(false);
    }
  }, [navigate, patientId, testingFacility])

  const onError = () => {
    enqueueSnackbar(
        "Please correct invalid fields.",
        {variant: 'error'});
  }

  const checkPatient = async (data: any) => {
    if (!executeRecaptcha) {
      return;
    }
    if (!testingFacility) {
      return;
    }
    setLoading(true);
    data.phone = parsePhoneNumber(data.phone).number;
    const token = await executeRecaptcha('getFacilityType'); //todo get another action string??
    axios.post(
        envConfig.currentAppEngineVerify + "getPatient",
        {
          dob: data.dob,
          phone: data.phone,
        },
        {headers: {recaptcha: token}}
    ).then((patient: { data: { id: string, facilityId: {} } }) => {
      if (setPatientId) {
        setPatientId(patient.data.id);
      }
      setLoading(false);
    }).catch((error) => {
      enqueueSnackbar(error.response?.data?.status, {variant: 'error'});
      setLoading(false);
    });
  }

  return (
      <Page title={"LabPort | Sign In"}>
        <Backdrop
            style={{zIndex: 4, backgroundColor: "rgba(0,0,0,0.8)"}}
            color={"black"}
            open={showBackdrop ?? false}>
          <Typography style={{color: "#fff", textAlign: "center", margin: "20px"}}>
            The system could not recognize where you came from.
            Please re-scan the QR code and start over.
          </Typography>
        </Backdrop>
        <form onSubmit={handleSubmit(checkPatient, onError)}>
          {loading && <LinearProgress/>}
          <Box
              height="95vh"
              sx={{
                display: 'flex',
                p: 1,
                borderRadius: 1,
                flexDirection: 'column',
                alignItems: 'center',
              }}
          >
            <Item sx={{flexGrow: 1}}/>
            <Item>
              <Typography
                  align={"center"}
                  gutterBottom
                  variant={"h3"}>
                Self Reporting
              </Typography>
              <Typography
                  align={"center"}
              >
                Enter your the phone number and the birthdate you used to register.
              </Typography>
            </Item>
            <Item>
              <ControlledMuiPhoneNumber
                  name={"phone"}
                  control={control}
                  label={"Phone Number"}
                  autoComplete={"tel"}
                  required
                  type={"tel"}
                  variant={"outlined"}
                  defaultCountry={'us'}
                  disableAreaCodes
                  countryCodeEditable
                  fullWidth/>
            </Item>
            <Item>
              <ControlledDesktopDatePicker
                  name={"dob"}
                  control={control}
                  label={"Date of Birth"}
                  autoComplete={"bday"}
                  required
                  inputFormat={"MM/dd/yyyy"}
                  minimumDate={new Date("1900-01-01")}
                  disableFuture/>
            </Item>
            <Item>
              <LoadingButton
                  loading={loading}
                  type={"submit"}
                  variant={"contained"}>
                <span>Submit</span>
              </LoadingButton>
            </Item>
            <Item sx={{flexGrow: 1}}/>
            <Item>
              <Typography>
                Need to register instead?
              </Typography>
            </Item>
            <Item>
              <Button
                  target={"_blank"}
                  href={"https://register.labport.app/?site=" + testingFacility}
                  variant={"outlined"}>
                Sign Up
              </Button>
            </Item>
            <Item sx={{flexGrow: 1}}/>
          </Box>
        </form>
      </Page>
  );
}
