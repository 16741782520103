import 'react-dropzone-uploader/dist/styles.css';
import Dropzone, {IFileWithMeta, StatusValue} from 'react-dropzone-uploader';
import {Dispatch, SetStateAction} from "react";
import {Stack, Typography} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

interface ImageUploaderInterface {
  setFile: Dispatch<SetStateAction<IFileWithMeta | undefined>>;
}

export const ImageUploader = (props: ImageUploaderInterface) => {
  const {setFile} = props;

  // // specify upload params and url for your files
  // const getUploadParams = (file: IFileWithMeta) => {
  //   console.log(file.meta, file.file);
  //   return {url: 'https://httpbin.org/post'};
  // };

  // called every time a file's `status` changes
  const handleChangeStatus = (
      file: IFileWithMeta,
      status: StatusValue,
      // allFiles: IFileWithMeta[],
  ) => {
    if (status === "done") {
      setFile(file);
    }
    if (status === "removed") {
      setFile(undefined);
    }
  };

  // // receives array of files that are done uploading when submit button is clicked
  // const handleSubmit = (successFiles: IFileWithMeta[], allFiles: IFileWithMeta[]) => {
  //   console.log(successFiles.map((f: IFileWithMeta) => f.meta));
  //   allFiles.forEach((f: IFileWithMeta) => f.remove());
  // }

  const InputContent = () => {
    return (
        <Stack
            key={Math.random()}
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
          <Typography variant={"h5"}>
            Upload your result image
          </Typography>
          <CloudUploadIcon
              style={{fontSize: 51}}
          />
        </Stack>
    );
  }

  return (
      <Dropzone
          // getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          // onSubmit={handleSubmit}
          inputContent={InputContent}
          accept="image/*"
          maxFiles={1}
          maxSizeBytes={10485760}
          multiple={false}
      />
  )
};
