import {Box, SxProps, Theme} from '@mui/material';

export default function Logo(sx: SxProps<Theme>) {
  return (
      <Box
          component="img"
          src="/static/logo.png"
          sx={{
            width: 143,
            height: 40,
            ...sx
          }}
      />
  );
}
