import {Backdrop, Grid, Typography} from "@mui/material";
import {useState} from "react";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {LoadingButton} from "@mui/lab";
import axios, {AxiosRequestConfig} from "axios";
import {useForm} from "react-hook-form";
import {IFileWithMeta} from "react-dropzone-uploader";
import Page from "../components/Page";
import {PatientContextInterface} from "../interfaces/PatientContextInterface";
import {usePatient} from "../contexts/PatientProvider";
import {envConfig} from "../const/envConfig";
import {useParamCheck} from "../hooks/useParamCheck";
import {useClientIP} from "../hooks/useClientIP";
import {
  ControlledAutocomplete,
  ControlledMobileDateTimePicker
} from "../components/ControlledComponents";
import {results} from "../const/results";
import {testTypes} from "../const/testTypes";
import {UserSampleData} from "../interfaces/UserSampleData";
import {ImageUploader} from "../components/ImageUploader";

export default function UploadResults() {
  const {
    control,
    handleSubmit,
  } = useForm<UserSampleData>();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const {
    patientId,
    testingFacility,
    executeRecaptcha,
    survey,
    showBackdrop,
  }: PatientContextInterface = usePatient();
  const [loading, setLoading] = useState(false);
  const [resultFile, setResultFile] = useState<IFileWithMeta>();

  const clientIP = useClientIP();
  useParamCheck({checkSite: true, checkPid: true});

  const onError = () => {
    enqueueSnackbar(
        "Please correct invalid fields.",
        {variant: 'error'});
  }

  const onSubmit = async (data: UserSampleData) => {
    if (!executeRecaptcha) {
      return;
    }
    if (!resultFile) {
      enqueueSnackbar("Please upload your result to continue!",
          {variant: 'error'});
      return;
    }
    setLoading(true);

    const formData = new FormData();
    formData.append("patientId", patientId ?? "");
    formData.append("facilityId", testingFacility ?? "");
    formData.append("questionnaire.symptom", survey?.symptom ?? "");
    formData.append("questionnaire.proximity", survey?.proximity ?? "");
    formData.append("sample.user.ip", clientIP ?? "");
    formData.append("sample.user.result", data.sample.user.result);
    formData.append("sample.user.date", new Date(data.sample.user.date).toString());
    formData.append("sample.user.testType", data.sample.user.testType);
    formData.append("sample.imageMeta.lastModified", resultFile.meta.lastModifiedDate);
    formData.append("sample.imageMeta.type", resultFile.file.type);

    const fileArrayBuffer = await resultFile.file.arrayBuffer();
    const fileBlob = new Blob([fileArrayBuffer], { type: resultFile.file.type });
    formData.append('resultFile', fileBlob, resultFile.file.name);

    const token = await executeRecaptcha('getFacilityType'); //todo get another action string??
    const config: AxiosRequestConfig = {
      method: 'post',
      url: envConfig.currentAppEngineSelfReport + "submitResult",
      data: formData,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        recaptcha: token,
      },
    };

    axios(config).then(function (response) {
      if (response.status === 200) {
        navigate("/thankyou");
      }
      setLoading(false);
    }).catch((error) => {
      if (!error.response) {
        enqueueSnackbar("Network Error: Connection to the server was refused!",
            {
              variant: 'error',
            });
      } else {
        const errorMessage = error.response.data?.message ?? "Network Error";
        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      }
      setLoading(false);
    });
  }

  return (
      <Page title={"LabPort | Upload"}>
        <Backdrop
            style={{zIndex: 4, backgroundColor: "rgba(0,0,0,0.8)"}}
            color={"black"}
            open={showBackdrop ?? false}>
          <Typography style={{color: "#fff", textAlign: "center", margin: "20px"}}>
            The system could not recognize where you came from.
            Please re-scan the QR code and start over.
          </Typography>
        </Backdrop>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{
                mt: 2,
                px: 2,
              }}
          >
            <Grid item xs={12}>
              <Typography
                  variant={"h4"}
                  sx={{textAlign: "center"}}>
                Test Result Upload
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ImageUploader setFile={setResultFile}/>
            </Grid>
            <Grid item sm={6} xs={12}>
              <ControlledAutocomplete
                  name={"sample.user.result"}
                  control={control}
                  label={"Result"}
                  options={results}
                  autoComplete={"off"}
                  required
                  variant={"outlined"}/>
            </Grid>
            <Grid item sm={6} xs={12}>
              <ControlledMobileDateTimePicker
                  name={"sample.user.date"}
                  control={control}
                  label={"Date & Time when test was taken"}
                  inputFormat={"MM/dd/yyyy hh:mm a"}
                  autoComplete={"new-password"}
                  minimumDate={new Date("2020-01-01")}
                  disableFuture/>
            </Grid>
            <Grid item sm={6} xs={12}>
              <ControlledAutocomplete
                  name={"sample.user.testType"}
                  control={control}
                  label={"Test Name"}
                  options={testTypes}
                  autoComplete={"off"}
                  required
                  variant={"outlined"}/>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                  loading={loading}
                  type={"submit"}
                  style={{width: "100%"}}
                  variant={"contained"}>
                <span>Submit</span>
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Page>
  );
}
