import {useEffect} from 'react';

interface ScriptArguments {
  url: string,
  position: string,
  async?: boolean
}

export const useScript = (options: ScriptArguments) => {
  useEffect(() => {
    const placement = document.querySelector(options.position);
    const script = document.createElement('script');

    script.src = options.url;
    script.async = typeof options.async === 'undefined' ? true : options.async;

    placement?.appendChild(script);
    return () => {
      placement?.removeChild(script);
    };
  }, [options.async, options.position, options.url]);
};
