import {SnackbarProvider} from 'notistack';
import {SelectionProvider} from "./contexts/PatientProvider";
import Router from './routes'

export default function App() {
  return (
      <SnackbarProvider maxSnack={3}>
        <SelectionProvider>
          <Router/>
        </SelectionProvider>
      </SnackbarProvider>
  );
}
