import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Checkbox,
  Fab,
  Grid,
  Typography
} from "@mui/material";
import {questionnaire} from "../const/questionnaire";
import {useEffect, useState} from "react";
import {PatientContextInterface} from "../interfaces/PatientContextInterface";
import {usePatient} from "../contexts/PatientProvider";
import CheckIcon from "@mui/icons-material/Check";

export default function QuestionnaireSymptomDetails(props: any) {
  const {setStep} = props;

  const {updateQuestionnaire}: PatientContextInterface = usePatient();
  const [symptomChoice, setSymptomChoice] = useState("");
  const [disableButton, setDisableButton] = useState(false);

  const selectSymptom = (name: string) => {
    if (symptomChoice === name) {
      setSymptomChoice("");
    } else {
      setSymptomChoice(name);
    }
  }

  useEffect(() => {
    if (symptomChoice) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [symptomChoice]);

  return (
      <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
          sx={{
            mt: 2,
            px: 2,
          }}
      >
        <Grid item xs={12}>
          <Typography
              variant={"h4"}
              sx={{textAlign: "center"}}>
            Please choose the option that best describes your symptoms:
          </Typography>
        </Grid>
        {questionnaire.symptomDetails.map((symptom) => {
          return (
              <Grid
                  key={symptom.name}
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  md={6}
                  xs={12}>
                <Card
                    sx={{maxWidth: "466px"}}>
                  <CardActionArea
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "start",
                      }}
                      onClick={() => selectSymptom(symptom.name)}
                  >
                    <CardHeader
                        avatar={
                          <Checkbox
                              style={{pointerEvents: "none"}}
                              checked={symptomChoice === symptom.name}
                          />
                        }
                        title={
                          <Typography variant={"h5"}>
                            {symptom.name}
                          </Typography>
                        }
                        sx={{pb: 0}}/>
                    <CardContent>
                      {symptom.list.map((item, i) => {
                        return (
                            <Grid
                                style={{display: "flex"}}
                                key={i}>
                              <CheckIcon
                                  sx={{color: 'primary.main'}}/>
                              <Typography align={"left"}>
                                {item}
                              </Typography>
                            </Grid>
                        );
                      })}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
          );
        })}
        <Grid
            item
            xs={12}
            sx={{
              textAlign: "center",
              position: "fixed",
              bottom: "1%",
            }}
        >
          <Fab
              variant={"extended"}
              onClick={() => {
                setStep("symptoms");
              }}
          >
            Back
          </Fab>
          <Fab
              color={"primary"}
              variant={"extended"}
              disabled={!disableButton}
              onClick={() => {
                if (symptomChoice === "Severe") {
                  setStep("severe");
                } else {
                  if (updateQuestionnaire) {
                    updateQuestionnaire({symptom: symptomChoice});
                  }
                  setStep("exposure");
                }
              }}
          >
            Confirm
          </Fab>
        </Grid>
      </Grid>
  );
}
