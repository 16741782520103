import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import axios from "axios";
import {envConfig} from "../const/envConfig";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {QuestionnaireInterface} from "../interfaces/QuestionnaireInterface";
import {PatientContextInterface} from "../interfaces/PatientContextInterface";

const SelectionContext = createContext<PatientContextInterface>({});

export function usePatient() {
  return useContext(SelectionContext);
}

interface SelectionProviderInterface {
  children?: ReactNode;
}

export const SelectionProvider = ({children}: SelectionProviderInterface) => {
  const {executeRecaptcha} = useGoogleReCaptcha();
  const [patientState, setPatientState] = useState({});
  const [patientId, setPatientId] = useState("");
  const [testingFacility, setTestingFacility] = useState("");
  const [facilityData, setFacilityData] = useState<{ settings: { enableSelfReporting: boolean } } | undefined>(undefined);
  const [survey, setSurvey] = useState<QuestionnaireInterface>();
  const [showBackdrop, setShowBackdrop] = useState(false);

  const updateQuestionnaire = (questionnaire: QuestionnaireInterface) => {
    setSurvey({
      ...survey,
      ...questionnaire,
    });
  };

  const value: PatientContextInterface = {
    patientState,
    setPatientState,
    patientId,
    setPatientId,
    testingFacility,
    setTestingFacility,
    facilityData,
    executeRecaptcha,
    survey,
    updateQuestionnaire,
    showBackdrop,
    setShowBackdrop,
  };

  useEffect(() => {
    if (facilityData) {
      return;
    }
    if (!executeRecaptcha) {
      return;
    }
    if (!testingFacility) {
      return;
    }
    (async () => {
      const token = await executeRecaptcha('getFacilityType'); //todo getFacilityType?
      axios.post(
          envConfig.currentAppEngineVerify + "getFacility",
          {
            facilityId: testingFacility,
          },
          {headers: {recaptcha: token}}
      ).then((facility: any) => { //todo create interface for facilityData
        setFacilityData(facility?.data);
      }).catch(() => {
        setTestingFacility("");
      });
    })();
  }, [executeRecaptcha, facilityData, setFacilityData, setTestingFacility, testingFacility]);

  useEffect(() => {
    if (testingFacility) {
      if (!facilityData) {
        setShowBackdrop(false);
      } else {
        if (facilityData.settings?.enableSelfReporting) {
          setShowBackdrop(false);
        } else {
          setShowBackdrop(true);
        }
      }
    } else {
      setShowBackdrop(true);
    }
  }, [facilityData, testingFacility]);

  return (
      <SelectionContext.Provider value={value}>
        {children}
      </SelectionContext.Provider>
  );
}
