import {Card, CardContent, CardHeader, Fab, Grid, Typography} from "@mui/material";

export default function QuestionnaireSevere(props: any) {
  const {setStep} = props;

  return (
      <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
          sx={{
            mt: 2,
            px: 2,
          }}
      >
        <Grid item xs={12}>
          <Typography
              variant={"h4"}
              sx={{textAlign: "center"}}>
            Please choose the option that best describes your symptoms:
          </Typography>
        </Grid>
        <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            xs={12}>
          <Card
              sx={{
                bgcolor: "error.main"
              }}
          >
            <CardHeader
                title={
                  <Typography variant={"h5"}>
                    Severe
                  </Typography>
                }
                sx={{pb: 0}}/>
            <CardContent>
              <Typography gutterBottom align={"left"}>
                Based on the information you provided,
                <strong> we recommend that you not delay medical attention </strong>
                and seek testing for the virus from your healthcare provider.
                Please reach out to your healthcare provider as soon as possible to discuss your
                next steps in care.
              </Typography>
              <Typography gutterBottom align={"left"}>
                <strong>If you feel like you are having a medical emergency,
                  please call 9-1-1.</strong>
              </Typography>
              <Typography align={"left"}>
                <strong>Call ahead before visiting your healthcare provider. </strong>
                This will help the healthcare provider's office keep other people from getting
                infected or exposed.
              </Typography>
              <ol
                  style={{
                    listStyleType: "disc",
                  }}>
                <li>
                  If you have a medical appointment,
                  let the healthcare provider know that you may have COVID-19.
                </li>
                <li>
                  Put on a face mask before you enter the facility to limit exposure to others.
                </li>
              </ol>
            </CardContent>
          </Card>
        </Grid>
        <Grid
            item
            xs={12}
            sx={{
              textAlign: "center",
              position: "fixed",
              bottom: "1%",
            }}>
          <Fab
              variant={"extended"}
              onClick={() => {
                setStep("symptomDetails");
              }}
          >
            Back
          </Fab>
        </Grid>
      </Grid>
  );
}
