import {StrictMode} from 'react';
import {render} from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {HelmetProvider} from "react-helmet-async";

render(
    <StrictMode>
      <HelmetProvider>
        <GoogleReCaptchaProvider
            reCaptchaKey="6LeHqOAbAAAAABIuce3yPKGN-O0tQTqIiqX7Cs-u"
            scriptProps={{
              async: false, // optional, default to false,
              defer: false, // optional, default to false
              appendTo: 'head', // optional, default to "head", can be "head" or "body",
              nonce: undefined // optional, default undefined
            }}>
          <App/>
        </GoogleReCaptchaProvider>
      </HelmetProvider>
    </StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
